import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-responsive-image',
  templateUrl: './responsive-image.component.html'
})
export class ResponsiveImageComponent {
  @Input() pathLg: string;
  @Input() pathSm: string;
  @Input() alt: string;
  @Input() width: number = 1024;
  @Input() height: number = 460;

}
