import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {map, Observable, timeout} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {MailMessageDto} from "../models/model";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  rootUrl = `${environment.apiUrl}mails`;
  isSendingMail = false;

  constructor(private http: HttpClient, private toastr: ToastrService) {
  }

  sendContactRequest(mailMessage: MailMessageDto): void {
    this.isSendingMail = true;
    const url = `${this.rootUrl}/send-contact-request`;
    var result = this.http.post(url, mailMessage)
      .pipe(
        map((data: any[]) => data.map(item => {
        }))
      )
      .subscribe(result => {
          console.log('result: ', result);

          // Do anything you want with the result here
          this.isSendingMail = false;
          this.toastr.success('Wir haben Ihre Kontaktanfrage erhalten.', 'Danke!', )
        },
        error => {
          console.error(error);
          this.toastr.error('Bei der Übermittlung ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie uns direkt per E-Mail auf arlette.gafner@uitikon.ch', 'Fehler!')
          this.isSendingMail = false;
        });
  }
}
