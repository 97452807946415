import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactService} from "../services/contact.service";
import {MailMessageDto} from "../models/model";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit {
  protected contactFormGroup: FormGroup;
  showErrors: boolean = false;
  isSendingMail = false;

  constructor(
    private formBuilder: FormBuilder,
    public contactService: ContactService,
    private toastr: ToastrService
  ) {}


  ngOnInit() {

    this.contactFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      // recaptcha: ['', Validators.required]
    });
  }

  submitForm() {
    if (this.contactFormGroup.invalid) {
      this.showErrors = true;
      console.log('contactFormGroup isInvald: ', this.contactFormGroup);

      return;
    }

    const message = new MailMessageDto();
    message.firstName = this.contactFormGroup.controls.firstName.value;
    message.lastName = this.contactFormGroup.controls.lastName.value;
    message.email = this.contactFormGroup.controls.email.value;
    message.message = this.contactFormGroup.controls.message.value;
    console.log('message: ', message);
    this.contactService.sendContactRequest(message)
  }

  toast(): void {
    this.toastr.success('Wir haben Ihre Kontaktanfrage erhalten.', 'Danke!', {
      timeOut: 300000,
    })

  }
}

