import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {AboutMeComponent} from "./pages/about-me/about-me.component";
import {PricesComponent} from "./pages/prices/prices.component";
import {ImprintComponent} from "./pages/imprint/imprint.component";
import {DisclaimerComponent} from "./pages/disclaimer/disclaimer.component";
import {FocusComponent} from "./pages/focus/focus.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'ueber-mich',
    component: AboutMeComponent,
  },
  {
    path: 'mein-fokus',
    component: FocusComponent,
  },
  {
    path: 'angebot-preise',
    component: PricesComponent,
  },
  {
    path: 'kontakt-oeffnungszeiten',
    component: ContactComponent,
  },
  {
    path: 'impressum',
    component: ImprintComponent,
  },
  {
    path: 'datenschutzerklaerung',
    component: DisclaimerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
