import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactComponent} from "./contact/contact.component";
import {HomeComponent} from "./home/home.component";
import {AboutMeComponent} from "./about-me/about-me.component";
import {PricesComponent} from "./prices/prices.component";
import {ImprintComponent} from "./imprint/imprint.component";
import {DisclaimerComponent} from "./disclaimer/disclaimer.component";
import {ResponsiveImageComponent} from "../componentens/responsive-image.component";
import {ContactFormComponent} from "../componentens/contact-form.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    ContactComponent,
    HomeComponent,
    AboutMeComponent,
    PricesComponent,
    ImprintComponent,
    DisclaimerComponent,
    ResponsiveImageComponent,
    ContactFormComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ],
  exports: [
    ContactComponent,
    HomeComponent,
    AboutMeComponent,
    PricesComponent,
    ImprintComponent,
    DisclaimerComponent
  ]
})
export class PagesModule {
}
